var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.020"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(0),
          _c("thead", [
            _c("tr", [
              _c("th", { attrs: { colspan: "2" } }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.021"))),
              ]),
              _vm._v(" "),
              _c("th", { attrs: { colspan: "2" } }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.022"))),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.023")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.024")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.023")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.024")))]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "text_left",
                  staticStyle: { "vertical-align": "top", "font-size": "11px" },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.mrk,
                        expression: "item.mrk",
                      },
                    ],
                    staticStyle: { "font-size": "11px" },
                    attrs: { rows: "17", readonly: "" },
                    domProps: { value: _vm.item.mrk },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "mrk", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "td",
                {
                  staticClass: "text_left",
                  staticStyle: { "vertical-align": "top", "font-size": "11px" },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.dscr,
                        expression: "item.dscr",
                      },
                    ],
                    staticStyle: { "font-size": "11px" },
                    attrs: { rows: "17", readonly: "" },
                    domProps: { value: _vm.item.dscr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "dscr", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "td",
                {
                  staticClass: "text_left",
                  staticStyle: { "vertical-align": "top", "font-size": "11px" },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.mrkShb,
                        expression: "item.mrkShb",
                      },
                    ],
                    staticStyle: { "font-size": "11px" },
                    attrs: { rows: "17", readonly: "" },
                    domProps: { value: _vm.item.mrkShb },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "mrkShb", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _c(
                "td",
                {
                  staticClass: "text_left",
                  staticStyle: { "vertical-align": "top", "font-size": "11px" },
                },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.dscrShb,
                        expression: "item.dscrShb",
                      },
                    ],
                    staticStyle: { "font-size": "11px" },
                    attrs: { rows: "17", readonly: "" },
                    domProps: { value: _vm.item.dscrShb },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "dscrShb", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              class: [
                "button",
                "sm",
                _vm.totalPageNumber == 0 ? "disabled" : "",
              ],
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage("first")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.073")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              class: [
                "button",
                "sm",
                "ml5",
                _vm.totalPageNumber == 0 ? "disabled" : "",
              ],
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage("prev")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.074")))]
          ),
          _vm._v(" "),
          _c("input", {
            staticClass: "wid80 text_center ml20",
            attrs: { type: "text", readonly: "" },
            domProps: { value: _vm.sizeText },
          }),
          _c(
            "a",
            {
              class: [
                "button",
                "sm",
                "ml20",
                _vm.totalPageNumber == 0 ? "disabled" : "",
              ],
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage("next")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.075")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              class: [
                "button",
                "sm",
                "ml5",
                _vm.totalPageNumber == 0 ? "disabled" : "",
              ],
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changePage("last")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.076")))]
          ),
        ]),
        _c("table", { staticClass: "tbl_col mt10" }, [
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.025")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.026")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.027")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.028")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.029")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.028")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.030")))]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.subItems, function (subItem, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(subItem.vslCd))]),
                _c("td", [_vm._v(_vm._s(subItem.etVoyNo))]),
                _c("td", [_vm._v(_vm._s(subItem.polPortCd))]),
                _c("td", [_vm._v(_vm._s(subItem.polTrmlCd))]),
                _c("td", [_vm._v(_vm._s(subItem.podPortCd))]),
                _c("td", [_vm._v(_vm._s(subItem.podTrmlCd))]),
                _c("td", [_vm._v(_vm._s(subItem.trstList))]),
              ])
            }),
            0
          ),
        ]),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "28%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "28%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }