<template>
  <div v-if="viewFlag">
    <h2 class="content_title">{{ $t('msg.ONEX010P090.020') }}</h2> <!-- B/L BODY -->
    <table class="tbl_col">
      <colgroup>
        <col style="width:21%">
        <col style="width:30%">
        <col style="width:21%">
        <col style="width:28%">
      </colgroup>
      <thead>
        <tr>
          <th colspan="2">{{ $t('msg.ONEX010P090.021') }}</th> <!-- Original Made Out -->
          <th colspan="2">{{ $t('msg.ONEX010P090.022') }}</th> <!-- Should Be -->
        </tr>
        <tr>
          <th>{{ $t('msg.ONEX010P090.023') }}</th> <!-- Marks & Nos -->
          <th>{{ $t('msg.ONEX010P090.024') }}</th> <!-- Description of Goods -->
          <th>{{ $t('msg.ONEX010P090.023') }}</th> <!-- Marks & Nos -->
          <th>{{ $t('msg.ONEX010P090.024') }}</th> <!-- Description of Goods -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text_left" style="vertical-align: top; font-size: 11px;"><textarea v-model="item.mrk" rows="17" readonly style="font-size:11px"></textarea></td>
          <td class="text_left" style="vertical-align: top; font-size: 11px;"><textarea v-model="item.dscr" rows="17" readonly style="font-size:11px"></textarea></td>
          <td class="text_left" style="vertical-align: top; font-size: 11px;"><textarea v-model="item.mrkShb" rows="17" readonly style="font-size:11px"></textarea></td>
          <td class="text_left" style="vertical-align: top; font-size: 11px;"><textarea v-model="item.dscrShb" rows="17" readonly style="font-size:11px"></textarea></td>
        </tr>
      </tbody>
    </table>

    <div class="text_center mt10">
      <a :class="['button', 'sm', totalPageNumber == 0 ? 'disabled' : '']" href="#" @click.prevent="changePage('first')">{{ $t('msg.ONEX010P090.073') }}</a> <!-- First -->
      <a :class="['button', 'sm', 'ml5', totalPageNumber == 0 ? 'disabled' : '']" href="#" @click.prevent="changePage('prev')">{{ $t('msg.ONEX010P090.074') }}</a> <!-- Previous -->
      <input type="text" :value="sizeText" class="wid80 text_center ml20" readonly>
      <a :class="['button', 'sm', 'ml20', totalPageNumber == 0 ? 'disabled' : '' ]" href="#" @click.prevent="changePage('next')">{{ $t('msg.ONEX010P090.075') }}</a> <!-- Next -->
      <a :class="['button', 'sm', 'ml5', totalPageNumber == 0 ? 'disabled' : '' ]" href="#" @click.prevent="changePage('last')">{{ $t('msg.ONEX010P090.076') }}</a> <!-- Last -->
    </div>

    <table class="tbl_col mt10">
      <colgroup>
        <col style="width:12%">
        <col style="width:12%">
        <col style="width:12%">
        <col style="width:12%">
        <col style="width:12%">
        <col style="width:12%">

        <col style="width:28%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.025') }}</th> <!-- VSL -->
          <th>{{ $t('msg.ONEX010P090.026') }}</th> <!-- VOY -->
          <th>{{ $t('msg.ONEX010P090.027') }}</th> <!-- POL -->
          <th>{{ $t('msg.ONEX010P090.028') }}</th> <!-- TMNL -->
          <th>{{ $t('msg.ONEX010P090.029') }}</th> <!-- POD -->
          <th>{{ $t('msg.ONEX010P090.028') }}</th> <!-- TMNL -->
          <th>{{ $t('msg.ONEX010P090.030') }}</th> <!-- Transit -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(subItem,index) in subItems" :key="index">
          <td>{{ subItem.vslCd }}</td>
          <td>{{ subItem.etVoyNo }}</td>
          <td>{{ subItem.polPortCd }}</td>
          <td>{{ subItem.polTrmlCd }}</td>
          <td>{{ subItem.podPortCd }}</td>
          <td>{{ subItem.podTrmlCd }}</td>
          <td>{{ subItem.trstList }}</td>
        </tr>
      </tbody>
    </table>
     <br>
  </div>
</template>

<script>
export default {
  name: 'ShippingLogBLDetailBLContainerPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: true,
      sizeText: '',
      item: {},
      totalPageNumber: 0, //전체페이지
      nowPageNumber: 0, //현재페이지
      subItems: []
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    console.log('B/L Detail Container Pop')
    this.init()
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    init () {
      if (this.mainData.blBody && this.mainData.blBody.length > 0) {
        //1.메인데이터 초기화

        this.item = this.mainData.blBody[0]
        //2.서브데이터 초기화
        this.subItems = this.mainData.blBodySub
        //3.뷰플래그 true
        this.viewFlag = true
        //4.페이징셋팅
        this.setSizeText()
      } else {
        this.item = {}
        this.subItems = []
        this.viewFlag = false
      }
    },
    //페이징 로직
    changePage (flag) {
      if (flag === 'first') { //첫 페이지 이동
        if (this.nowPageNumber === 1) {
          return
        }
        this.nowPageNumber = 1
      } else if (flag === 'prev') { //이전 페이지 이동
        if (this.nowPageNumber === 1) {
          return
        }
        this.nowPageNumber = this.nowPageNumber - 1
      } else if (flag === 'next') { //다음 페이지 이동
        if (this.nowPageNumber === this.totalPageNumber) {
          return
        }
        this.nowPageNumber = this.nowPageNumber + 1
      } else if (flag === 'last') { //마지막 페이지 이동
        if (this.nowPageNumber === this.totalPageNumber) {
          return
        }
        this.nowPageNumber = this.totalPageNumber
      }
      this.item = this.mainData.blBody[this.nowPageNumber - 1]
      this.sizeText = this.nowPageNumber + '/' + this.totalPageNumber
    },
    setSizeText () {
      this.totalPageNumber = this.mainData.blBody.length
      this.nowPageNumber = this.mainData.blBody.length === 0 ? 0 : 1
      this.sizeText = this.nowPageNumber + '/' + this.totalPageNumber
    }
  }
}
</script>
